import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useHistory } from "react-router-dom";

const MenuActions = () => {
  const history = useHistory();

  const profileNavigationHandler = (event) => {
    event.preventDefault();
    history.push("/account");
  };

  return (
    <section className="py-2 mb-4 actions-background">
      <Container>
        <Row>
          <Col md={3} className="d-grid">
            <Button variant="light" onClick={profileNavigationHandler}>
              <i className="fas fa-arrow-left"> </i> Profile
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MenuActions;
