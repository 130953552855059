import { Redirect, Route, Switch } from "react-router-dom";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { authActions } from "./store/auth";

import MainHeader from "./components/layout/MainHeader";
import MainFooter from "./components/layout/MainFooter";
import Home from "./pages/Home";
import UserAccount from "./pages/UserAccount";
import Cases from "./pages/Cases";
import CaseDetail from "./pages/CaseDetail";
import NotFound from "./pages/NotFount";
import LoginPage from "./pages/LoginPage";
import Students from "./pages/Students";
import ResetPassword from "./pages/ResetPassword";
import RegisterUserForm from "./components/auth/RegisterUserForm";
import UnallocatedCases from "./pages/UnallocatedCases";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import ForgotPassword from "./pages/ForgotPassword";
import Reviewers from "./pages/Reviewers";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const expires = useSelector((state) => state.auth.expires);

  const dispatch = useDispatch();

  const expiresIn = expires - Date.now();

  if (expiresIn < 1) {
    dispatch(authActions.logout());
  }

  return (
    <Fragment>
      <MainHeader />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/register_user">
          {isAuthenticated ? <RegisterUserForm /> : <Redirect to="/login" />}
        </Route>
        <Route path="/login">{!isAuthenticated && <LoginPage />}</Route>
        <Route path="/account">
          {isAuthenticated ? <UserAccount /> : <Redirect to="/login" />}
        </Route>
        <Route path="/cases" exact>
          {isAuthenticated ? <Cases /> : <Redirect to="/login" />}
        </Route>
        <Route path="/cases/:caseId">
          {isAuthenticated ? <CaseDetail /> : <Redirect to="/login" />}
        </Route>
        <Route path="/unallocated_cases">
          {isAuthenticated ? <UnallocatedCases /> : <Redirect to="/login" />}
        </Route>
        <Route path="/students">
          {isAuthenticated ? <Students /> : <Redirect to="/login" />}
        </Route>
        <Route path="/reviewers">
          {isAuthenticated ? <Reviewers /> : <Redirect to="/login" />}
        </Route>
        <Route path="/reset_password/:resetToken">
          <ResetPassword />
        </Route>
        <Route path="/change_password">
          {isAuthenticated ? <ChangePasswordPage /> : <Redirect to="/login" />}
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <MainFooter />
    </Fragment>
  );
}

export default App;
