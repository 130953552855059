import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { alertActions } from "../../store/alert";
import axios from "axios";

const ChangePasswordForm = () => {
  const [enteredCurrentPassword, setEnteredCurrentPassword] = useState("");
  const [currentPasswordBlurred, setCurrentPasswordBlurred] = useState(false);
  const [enteredNewPassword, setEnteredNewPassword] = useState("");
  const [newPasswordBlurred, setNewPasswordBlurred] = useState(false);
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState("");
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);

  const isCurrentPasswordValid =
    enteredCurrentPassword.trim() !== "" &&
    enteredCurrentPassword.trim().length >= 6;
  const isNewPasswordValid =
    enteredNewPassword.trim() !== "" && enteredNewPassword.trim().length >= 6;
  const isConfirmPasswordValid =
    enteredConfirmPassword.trim() !== "" &&
    enteredConfirmPassword.trim().length >= 6 &&
    enteredNewPassword.trim() === enteredConfirmPassword.trim();
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const currentPasswordChangeHandler = (event) => {
    setEnteredCurrentPassword(event.target.value);
  };

  const currentPasswordBlur = (event) => {
    setCurrentPasswordBlurred(true);
  };

  const newPasswordChangeHandler = (event) => {
    setEnteredNewPassword(event.target.value);
  };

  const newPasswordBlur = (event) => {
    setNewPasswordBlurred(true);
  };

  const confirmPasswordChangeHandler = (event) => {
    setEnteredConfirmPassword(event.target.value);
  };

  const confirmPasswordBlur = (event) => {
    setConfirmPasswordBlurred(true);
  };

  let isFormValid = false;

  if (isCurrentPasswordValid && isNewPasswordValid && isConfirmPasswordValid) {
    isFormValid = true;
  }

  async function submitHandler(event) {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }

    try {
      const body = {
        current_password: enteredCurrentPassword,
        new_password: enteredNewPassword,
        confirm_new_password: enteredConfirmPassword,
      };
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/change_password/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: body,
      });
      if (response.status === 200) {
        dispatch(
          alertActions.flashMessage({
            message: "password changes successfully",
            alertType: "success",
          })
        );
        history.replace("/account");
      }
    } catch (err) {
      const message = err.response.data;
      dispatch(alertActions.flashMessage({ message, alertType: "danger" }));
      setEnteredCurrentPassword("");
      setEnteredNewPassword("");
      setEnteredConfirmPassword("");
    }
  }

  return (
    <Container className="my-4">
      <Row>
        <Col md={5} className="mx-auto">
          <Card>
            <Card.Header className="bg-secondary text-white">Login</Card.Header>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-4">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={enteredCurrentPassword}
                    onChange={currentPasswordChangeHandler}
                    onBlur={currentPasswordBlur}
                    isValid={isCurrentPasswordValid}
                    isInvalid={
                      !isCurrentPasswordValid && currentPasswordBlurred
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter your password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={enteredNewPassword}
                    onChange={newPasswordChangeHandler}
                    onBlur={newPasswordBlur}
                    isValid={isNewPasswordValid}
                    isInvalid={!isNewPasswordValid && newPasswordBlurred}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter your new password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={enteredConfirmPassword}
                    onChange={confirmPasswordChangeHandler}
                    onBlur={confirmPasswordBlur}
                    isValid={isConfirmPasswordValid}
                    isInvalid={
                      !isConfirmPasswordValid && confirmPasswordBlurred
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    password should match.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-grid">
                  <Button type="submit">Change Password</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePasswordForm;
