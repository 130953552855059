import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { authActions } from "../../store/auth";
import { alertActions } from "../../store/alert";
import axios from "axios";

const LoginForm = () => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordBlurred, setPasswordBlurred] = useState(false);

  const isEmailValid = enteredEmail.trim() !== "" && enteredEmail.includes("@");
  const isPasswordValid =
    enteredPassword.trim() !== "" && enteredPassword.trim().length >= 6;

  const dispatch = useDispatch();
  const history = useHistory();

  const emailInputChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const emailBlur = (event) => {
    setEmailBlurred(true);
  };

  const passwordInputChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const passwordBlur = (event) => {
    setPasswordBlurred(true);
  };

  let isFormValid = false;

  if (isEmailValid && isPasswordValid) {
    isFormValid = true;
  }

  async function submitHandler(event) {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    try {
      const body = { username: enteredEmail, password: enteredPassword };

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/token/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      });

      if (response.status === 200) {
        dispatch(
          authActions.login({
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
          })
        );
        dispatch(
          alertActions.flashMessage({
            message: "user successfully logged in",
            alertType: "success",
          })
        );

        history.replace("/account");
      }
    } catch (err) {
      const message = err.response.data.detail;
      dispatch(alertActions.flashMessage({ message, alertType: "danger" }));
      console.log(message);

      setEnteredPassword("");
      setEnteredEmail("");
    }
  }

  return (
    <Container className="my-4">
      <Row>
        <Col md={5} className="mx-auto">
          <Card>
            <Card.Header className="bg-secondary text-white">Login</Card.Header>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={enteredEmail}
                    onChange={emailInputChangeHandler}
                    onBlur={emailBlur}
                    isValid={isEmailValid}
                    isInvalid={!isEmailValid && emailBlurred}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter your email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={enteredPassword}
                    onChange={passwordInputChangeHandler}
                    onBlur={passwordBlur}
                    isValid={isPasswordValid}
                    isInvalid={!isPasswordValid && passwordBlurred}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter your password.
                  </Form.Control.Feedback>
                  <Row>
                    <Col className="mt-2">
                      <a href="/forgot_password" className="fs-6">
                        forgot password?
                      </a>
                    </Col>
                  </Row>
                </Form.Group>
                <div className="d-grid">
                  <Button type="submit">Login</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
