import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const ReviewerCardItem = (props) => {
  const [reviewerCases, setReviewerCases] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const getReviewerCases = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/reviewer_cases/${props.reviewer.id}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          const fetchedReviewerCases = response.data;
          setReviewerCases(fetchedReviewerCases);
        }
      } catch (err) {
        const errMessage = err.response.data.detail;
        console.log(errMessage);
      }
    };
    getReviewerCases();
  }, [accessToken, dispatch, props.reviewer.id]);

  return (
    <tr>
      <td>{props.reviewer.id}</td>
      <td>{`${props.reviewer.email}`}</td>
      <td>{`${props.reviewer.first_name}`}</td>
      <td>{reviewerCases.length}</td>
      <td></td>
    </tr>
  );
};
export default ReviewerCardItem;
