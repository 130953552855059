import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import StatusBadge from "../layout/StatusBadge";
import UpdateCaseAction from "./UpdateCaseAction";
import Button from "react-bootstrap/esm/Button";

import { useState } from "react";

const CaseDetailCard = (props) => {
  const dateCreated = new Date(props.case.created);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [showUpdateCase, setShowUpdateCase] = useState(false);

  const toggleShowUpdateCase = () => {
    setShowUpdateCase(!showUpdateCase);
  };

  return (
    <Card>
      <Card.Header>
        <h4>Case Detail</h4>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={2}>
            Status <StatusBadge status={props.case.status} />
          </Col>
          <Col md={2}>
            Student: <span className="fw-bold">{props.case.student.name}</span>
          </Col>
          <Col md={3}>
            Reviewer:{" "}
            <span className="fw-bold">
              {props.case.reviewer ? props.case.reviewer.name : "not allocated"}
            </span>
          </Col>
          <Col md={2}>
            Created:{" "}
            <span className="fw-bold">
              {dateCreated.toLocaleString("en-AU", options)}
            </span>
          </Col>
          <Col>
            {showUpdateCase && props.currentUser.group === "reviewer" && (
              <UpdateCaseAction
                status={props.case.status}
                caseId={props.case.id}
                onUpdateCase={toggleShowUpdateCase}
              />
            )}
            {!showUpdateCase && props.currentUser.group === "reviewer" && (
              <Button onClick={toggleShowUpdateCase}>Update Case</Button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CaseDetailCard;
