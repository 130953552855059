import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import creativity from "../../img/creativity.svg";
import timemakers from "../../img/tmc.png";
import studyadelaide from "../../img/study_adelaide.png";

const Landing = () => {
  return (
    <Container className="px-5">
      <Row className="align-items-center mt-4" gx={5}>
        <Col lg={7}>
          <Row>
            <Col>
              <h1 className="page-header-ui-title">
                Get feedback from Career Professionals
              </h1>
              <p className="page-header-ui-text mb-5">
                Have assistance from a professional to review your job
                application. Upload your resume, cover letter and the job ad and
                you will get feedback on what you did well and what can be
                improved. By knowing what you can do better and implementing
                those changes, you will increase your chances of getting an
                interview next time.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <img
                src={studyadelaide}
                alt="study adelaide"
                className="rounded img-fluid"
              ></img>
            </Col>
            <Col lg={6}>
              <img
                src={timemakers}
                alt="timemakers consulting"
                className="rounded img-fluid"
              ></img>
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <div className="p-4">
            <img
              src={creativity}
              alt="userprofile"
              className="rounded img-fluid"
            ></img>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Landing;
