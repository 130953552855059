import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useHistory } from "react-router-dom";

const AccountActions = (props) => {
  const history = useHistory();

  const editProfileHandler = () => {
    props.onEditProfile();
  };

  const changePasswordHandler = () => {
    history.push("/change_password");
  };

  const homeNavigationHandler = (event) => {
    event.preventDefault();
    history.push("/home");
  };

  return (
    <section className="py-2 mb-4 actions-background">
      <Container>
        <Row>
          <Col md={3} className="d-grid">
            <Button variant="light" onClick={homeNavigationHandler}>
              Home
            </Button>
          </Col>
          <Col md={3} className="d-grid">
            <Button variant="success" onClick={editProfileHandler}>
              <i className="fas fa-pen"></i> Edit Profile
            </Button>
          </Col>
          <Col md={3} className="d-grid">
            <Button variant="danger" onClick={changePasswordHandler}>
              <i className="fas fa-lock"></i> Change Password
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AccountActions;
