import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import FileCardItem from "./FileCardItem";
import UploadFile from "./UploadFile";

const ReviewCard = (props) => {
  const reviews = props.reviews;
  const reviewsType = props.reviews.map((file) => {
    return file.file_type;
  });

  const uploadToRender = ["RE"];

  const toRender = uploadToRender.filter(
    (uploadCard) => !reviewsType.includes(uploadCard)
  );

  const itemsToUpload = {
    CV: "Curriculum",
    CL: "Cover Letter",
    JA: "Job Ad",
    RE: "Review",
  };

  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Review</h4>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {toRender.map((card) => (
            <UploadFile
              canUpload={props.currentUser.group === "reviewer"}
              case={props.case}
              key={card}
              type={itemsToUpload[card]}
            />
          ))}

          {reviews.length === 0 && props.currentUser.group === "student" ? (
            <div className="fs-3 text-center">No Reviews Uploaded</div>
          ) : (
            reviews.map((review) => (
              <FileCardItem
                canDelete={props.currentUser.group === "reviewer"}
                key={review.id}
                file={review}
                case={props.case}
              />
            ))
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default ReviewCard;
