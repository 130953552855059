import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const UpdateCaseAction = (props) => {
  const [selectedStatus, setSelectedStatus] = useState({
    caseStatus: props.status,
  });
  const accessToken = useSelector((state) => state.auth.accessToken);

  const updateCaseHandler = async (event) => {
    event.preventDefault();

    try {
      const data = { status: selectedStatus.caseStatus };

      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/update_case/${props.caseId}`,
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        window.location.reload();
        props.onUpdateCase();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCaseStatusChange = (event) => {
    setSelectedStatus({ ...selectedStatus, caseStatus: event.target.value });
  };
  return (
    <Form onSubmit={updateCaseHandler}>
      <InputGroup>
        <Form.Select
          onChange={handleCaseStatusChange}
          value={selectedStatus.caseStatus}
        >
          <option value="P">Pending</option>
          <option value="S">Started</option>
          <option value="C">Completed</option>
        </Form.Select>
        <Button type="submit">Update Case</Button>
      </InputGroup>
    </Form>
  );
};

export default UpdateCaseAction;
