import Button from "react-bootstrap/Button";
import StatusBadge from "../layout/StatusBadge";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";

// import { useHistory } from "react-router-dom";

const UnallocatedCasesCardItem = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const caseId = props.case.id;
  const dispatch = useDispatch();

  const dateCreated = new Date(props.case.created);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const allocateCaseHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/allocate_case/${caseId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const message = response.data.message;
        dispatch(
          alertActions.flashMessage({
            message,
            alertType: "success",
          })
        );
      }
      window.location.reload();
    } catch (err) {
      dispatch(
        alertActions.flashMessage({
          message: err.response.data.detail,
          alertType: "danger",
        })
      );
    }
  };

  // const history = useHistory();

  return (
    <tr>
      <td>{props.case.id}</td>
      <td>{dateCreated.toLocaleString("en-AU", options)}</td>
      <td> {props.case.student.name} </td>
      <td>
        <StatusBadge status={props.case.status} />
      </td>
      <td>
        <Button variant="success" onClick={allocateCaseHandler}>
          Allocate to me
        </Button>
      </td>
    </tr>
  );
};
export default UnallocatedCasesCardItem;
