import { Fragment } from "react";

import MenuActions from "../components/layout/MenuActions";
import ReviewerCard from "../components/reviewer/ReviewerCard";

const Reviewers = () => {
  return (
    <Fragment>
      <MenuActions />
      <ReviewerCard />
    </Fragment>
  );
};

export default Reviewers;
