import { createSlice } from "@reduxjs/toolkit";

const groupsMap = {
  2: "reviewer",
  1: "student",
};

const initialState = {
  me: {
    username: "",
    email: "",
    name: "",
    lastName: "",
    group: "",
    is_superuser: false,
    profile: {
      id: 0,
      date_of_birth: "",
      user: 0,
    },
  },
};

const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    updateMe(state, action) {
      let me = action.payload;
      me = { ...me, group: me.groups ? groupsMap[me.groups] : null };
      state.me = me;
    },
  },
});

export const meActions = meSlice.actions;

export default meSlice.reducer;
