import Button from "react-bootstrap/Button";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { alertActions } from "../../store/alert";

const StudentCardItem = (props) => {
  const [studentCases, setStudentCases] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const getStudentCases = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/student_cases/${props.student.id}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          const fetchedStudentCases = response.data;
          setStudentCases(fetchedStudentCases);
        }
      } catch (err) {
        const errMessage = err.response.data.detail;
        console.log(errMessage);
      }
    };
    getStudentCases();
  }, [accessToken, dispatch, props.student.id]);

  const createCaseHandler = (event) => {
    event.preventDefault();
    const createCase = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/create_case/${props.student.id}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status > 299) {
          throw new Error("could not create case");
        }

        dispatch(
          alertActions.flashMessage({
            message: `successfully created case for student ${props.student.first_name}`,
            alertType: "success",
          })
        );

        window.location.reload();
      } catch (err) {
        const errMessage = err.response.data.detail;
        console.log(errMessage);
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    createCase();
  };

  return (
    <tr>
      <td>{props.student.id}</td>
      <td>{`${props.student.email}`}</td>
      <td>{`${props.student.first_name}`}</td>
      <td>{studentCases.length}</td>
      <td>
        <Button variant="success" onClick={createCaseHandler}>
          <i className="fas fa-plus"></i> Create New Case
        </Button>
      </td>
    </tr>
  );
};
export default StudentCardItem;
