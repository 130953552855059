import { Fragment } from "react";

import StudentCard from "../components/student/StudentCard";
import MenuActions from "../components/layout/MenuActions";

const Students = () => {
  return (
    <Fragment>
      <MenuActions />
      <StudentCard />
    </Fragment>
  );
};

export default Students;
