import UnallocatedCasesCard from "../components/case/UnallocatedCasesCard";
import CaseActions from "../components/case/CaseActions";

import { Fragment } from "react";

const UnallocatedCases = () => {
  return (
    <Fragment>
      <CaseActions />
      <UnallocatedCasesCard />;
    </Fragment>
  );
};

export default UnallocatedCases;
