import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import CasesCardItem from "./CasesCardItem";
import { alertActions } from "../../store/alert";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { authenticatedUser } from "../../store/session-actions";

const CasesCard = () => {
  const [myCases, setMyCases] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  // const currentUser = useSelector((state) => state.me.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticatedUser(accessToken));
  }, [accessToken, dispatch]);

  useEffect(() => {
    const getMyCases = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/my_cases/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          const fetchedCases = response.data;
          setMyCases(fetchedCases);
        }
      } catch (err) {
        const errMessage = err.response.data.detail;
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    getMyCases();
  }, [accessToken, dispatch]);

  if (!myCases) {
    return (
      <Container>
        <Row>
          <Col className="mx-auto" md={3}>
            <span className="fs-3"> Fetching Cases... </span>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          <h4>My Cases</h4>
        </Card.Header>
        <Table stripped="true">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Date created</th>
              <th>Student</th>
              <th>Reviewer</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {myCases.map((caseItem) => (
              <CasesCardItem key={caseItem.id} case={caseItem} />
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default CasesCard;
