import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

const AccountProfile = (props) => {
  return (
    <Card>
      <Card.Header>
        <h4>User Profile</h4>
        <Badge bg="bg bg-secondary">
          {props.user.is_superuser ? "super user" : props.user.group}
        </Badge>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            Name: <span className="fw-bold">{props.user.first_name}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            Last Name: <span className="fw-bold">{props.user.last_name}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            Email: <span className="fw-bold">{props.user.email}</span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default AccountProfile;
