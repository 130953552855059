import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { alertActions } from "../../store/alert";

const EditProfileForm = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [enteredName, setEnteredName] = useState(props.user.first_name);
  const [enteredLastName, setEnteredLastName] = useState(props.user.last_name);
  const [enteredEmail, setEnteredEmail] = useState(props.user.email);

  const dispatch = useDispatch();

  const enteredNameHandler = (event) => {
    setEnteredName(event.target.value);
  };

  const enteredLastNameHandler = (event) => {
    setEnteredLastName(event.target.value);
  };

  const enteredEmailHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const editProfileBody = {
        username: enteredEmail,
        email: enteredEmail,
        first_name: enteredName,
        last_name: enteredLastName,
        profile: {
          date_of_birth: "1980-01-01",
        },
      };

      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/me/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: editProfileBody,
      });

      if (response.status === 200) {
        dispatch(
          alertActions.flashMessage({
            message: "user profile successfully updated",
            alertType: "success",
          })
        );
        // props.onSave();
        window.location.reload();
      }
    } catch (err) {
      const errMessage = err.response.data.detail;
      dispatch(alertActions.flashMessage({ errMessage, alertType: "danger" }));
    }
  };

  return (
    <Card>
      <Card.Header className="bg-secondary text-white">Edit</Card.Header>
      <Card.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={enteredNameHandler}
              value={enteredName}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              onChange={enteredLastNameHandler}
              value={enteredLastName}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={enteredEmailHandler}
              value={enteredEmail}
            />
          </Form.Group>
          <div className="d-grid mt-4">
            <Button variant="success" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditProfileForm;
