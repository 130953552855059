import { meActions } from "./me";

import axios from "axios";

export const authenticatedUser = (accessToken) => {
  return async (dispatch) => {
    const getUser = async () => {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/me/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("could not fetch data");
      }

      const data = response.data;

      return data;
    };

    try {
      const currentUser = await getUser();

      dispatch(meActions.updateMe(currentUser));
    } catch (err) {
      console.log(err);
    }
  };
};
