import { useParams } from "react-router-dom";
import { Fragment } from "react";
import Container from "react-bootstrap/Container";

import CaseDetailActions from "../components/case/CaseDetailActions";
import CaseDetailCard from "../components/case/CaseDetailCard";
import FileCard from "../components/file/FileCard";
import ReviewCard from "../components/file/ReviewCard";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { alertActions } from "../store/alert";
import { authenticatedUser } from "../store/session-actions";

const CaseDetail = () => {
  const params = useParams();
  const caseDetailId = params.caseId;
  const [caseDetailData, setCaseDetailData] = useState();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const currentUser = useSelector((state) => state.me.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticatedUser(accessToken));
  }, [accessToken, dispatch]);

  useEffect(() => {
    const getMyCases = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/cases/${caseDetailId}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          const fetchedCase = response.data;
          setCaseDetailData(fetchedCase);
        }
      } catch (err) {
        if (err.response.status === 404) {
          dispatch(
            alertActions.flashMessage({
              message: err.response.statusText,
              alertType: "danger",
            })
          );
        }
        let errMessage = err.response.data.detail;
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    getMyCases();
  }, [accessToken, dispatch, caseDetailId]);

  if (caseDetailData) {
    return (
      <Fragment>
        <CaseDetailActions />
        <Container>
          <CaseDetailCard case={caseDetailData} currentUser={currentUser} />
          <FileCard
            currentUser={currentUser}
            case={caseDetailId}
            files={caseDetailData.files.filter(
              (fileItem) => fileItem.file_type !== "RE"
            )}
          />
          <ReviewCard
            currentUser={currentUser}
            case={caseDetailId}
            reviews={caseDetailData.files.filter(
              (fileItem) => fileItem.file_type === "RE"
            )}
          />
        </Container>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Container className="my-4">
        <h3 className="Display-4"> No case for id: {caseDetailId}</h3>
      </Container>
    </Fragment>
  );
};

export default CaseDetail;
