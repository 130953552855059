import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Button from "react-bootstrap/Button";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import { useHistory } from "react-router-dom";
import axios from "axios";

const RegisterUserForm = () => {
  const [accountTypeInput, setAccountTypeInput] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordBlurred, setPasswordBlurred] = useState(false);
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState("");
  const [confirmPasswordBlurred, setConfirmPasswordBlurred] = useState(false);
  // const history = useHistory();
  const dispatch = useDispatch();

  const isEmailValid = enteredEmail.trim() !== "" && enteredEmail.includes("@");
  const isPasswordValid =
    enteredPassword.trim() !== "" && enteredPassword.trim().length >= 6;
  const isConfirmPasswordValid =
    enteredConfirmPassword.trim() !== "" &&
    enteredConfirmPassword.trim().length >= 6 &&
    enteredPassword.trim() === enteredConfirmPassword.trim();
  const history = useHistory();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const handleChange = (event) => {
    setAccountTypeInput(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const emailBlur = (event) => {
    setEmailBlurred(true);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const passwordBlur = (event) => {
    setPasswordBlurred(true);
  };

  const confirmPasswordChangeHandler = (event) => {
    setEnteredConfirmPassword(event.target.value);
  };

  const confirmPasswordBlur = (event) => {
    setConfirmPasswordBlurred(true);
  };

  let isFormValid = false;

  if (isEmailValid && isPasswordValid && isConfirmPasswordValid) {
    isFormValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }

    try {
      const enteredUserAccountType = accountTypeInput;
      const body = {
        group: enteredUserAccountType,
        username: enteredEmail,
        email: enteredEmail,
        password: enteredPassword,
        confirm_password: enteredConfirmPassword,
      };

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/register/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: body,
      });

      if (response.status === 200) {
        dispatch(
          alertActions.flashMessage({
            message: `user ${enteredEmail} was successfully created`,
            alertType: "success",
          })
        );
        history.push("/account");
      }
    } catch (err) {
      const message = err.response.data;
      if (message["creating user error"]) {
        dispatch(
          alertActions.flashMessage({
            message: "user email already exists chose another one",
            alertType: "danger",
          })
        );
      }
      if (message["password"]) {
        dispatch(
          alertActions.flashMessage({
            message:
              "password error. Cannot be simple use number anc characters and longer that 8 digits",
            alertType: "danger",
          })
        );
      }

      setAccountTypeInput("");
      setEnteredEmail("");
      setEnteredPassword("");
      setEnteredConfirmPassword("");
    }
  };

  return (
    <Container className="my-4">
      <Row>
        <Col md={6} className="mx-auto">
          <Card>
            <Card.Header className="bg-secondary text-white">
              Register New User{" "}
            </Card.Header>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Row className="g-3">
                  <Col md={12}>
                    <FormCheck>
                      <FormCheck.Input
                        type="radio"
                        name="account"
                        value="student"
                        checked={accountTypeInput === "student"}
                        onChange={handleChange}
                      />
                      <FormCheck.Label>Student</FormCheck.Label>
                    </FormCheck>
                    <FormCheck>
                      <FormCheck.Input
                        type="radio"
                        name="account"
                        value="reviewer"
                        checked={accountTypeInput === "reviewer"}
                        onChange={handleChange}
                      />
                      <FormCheck.Label>Reviewer</FormCheck.Label>
                    </FormCheck>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={enteredEmail}
                        onChange={emailChangeHandler}
                        onBlur={emailBlur}
                        isValid={isEmailValid}
                        isInvalid={!isEmailValid && emailBlurred}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={enteredPassword}
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlur}
                        isValid={isPasswordValid}
                        isInvalid={!isPasswordValid && passwordBlurred}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter a password.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={enteredConfirmPassword}
                        onChange={confirmPasswordChangeHandler}
                        onBlur={confirmPasswordBlur}
                        isValid={isConfirmPasswordValid}
                        isInvalid={
                          !isConfirmPasswordValid && confirmPasswordBlurred
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Password has to match.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <div className="d-grid">
                    <Button type="submit">Register User</Button>
                  </div>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterUserForm;
