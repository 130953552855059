import Button from "react-bootstrap/Button";
import StatusBadge from "../layout/StatusBadge";

import { useHistory } from "react-router-dom";

const CasesCardItem = (props) => {
  const dateCreated = new Date(props.case.created);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const history = useHistory();

  const caseDetailNavigationHandler = (event) => {
    event.preventDefault();

    history.push(`cases/${props.case.id}`);
  };

  return (
    <tr>
      <td>{props.case.id}</td>
      <td>{dateCreated.toLocaleString("en-AU", options)}</td>
      <td> {props.case.student.name} </td>
      <td>
        {props.case.reviewer ? props.case.reviewer.name : "not allocated"}
      </td>
      <td>
        <StatusBadge status={props.case.status} />
      </td>
      <td>
        <Button variant="secondary" onClick={caseDetailNavigationHandler}>
          <i className="fas fa-arrow-right"></i> Detail
        </Button>
      </td>
    </tr>
  );
};
export default CasesCardItem;
