import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import FileCardItem from "./FileCardItem";
import UploadFile from "./UploadFile";
import MessageWarningFileType from "../layout/MessageWarningFileType";

const FileCard = (props) => {
  const files = props.files;
  const filesType = props.files.map((file) => {
    return file.file_type;
  });

  const uploadToRender = ["CV", "CL", "JA"];

  const toRender = uploadToRender.filter(
    (uploadCard) => !filesType.includes(uploadCard)
  );

  const itemsToUpload = {
    CV: "Curriculum",
    CL: "Cover Letter",
    JA: "Job Ad",
    RE: "Review",
  };

  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Files</h4>
      </Card.Header>
      <Card.Body>
        <MessageWarningFileType />
        <ListGroup variant="flush">
          {toRender.map((card) => (
            <UploadFile
              canUpload={props.currentUser.group === "student"}
              case={props.case}
              key={card}
              type={itemsToUpload[card]}
            />
          ))}
          {files.length === 0 && props.currentUser.group === "reviewer" ? (
            <div className="fs-3 text-center">No Files Uploaded</div>
          ) : (
            files.map((file) => (
              <FileCardItem
                key={file.id}
                file={file}
                case={props.case}
                canDelete={props.currentUser.group === "student"}
              />
            ))
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default FileCard;
