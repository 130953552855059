import AccountActions from "../components/account/AccountActions";
import AccountProfile from "../components/account/AccountProfile";
import CasesMenu from "../components/account/CasesMenu";
import EditProfileForm from "../components/account/EditProfileForm";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authenticatedUser } from "../store/session-actions";

const UserAccount = () => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const currentUser = useSelector((state) => state.me.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticatedUser(accessToken));
  }, [accessToken, dispatch]);

  const toggleEditProfileForm = () => {
    setShowEditProfile(!showEditProfile);
  };

  if (currentUser) {
    return (
      <div>
        <AccountActions onEditProfile={toggleEditProfileForm} />
        <Container>
          <Row>
            <Col md={9}>
              {!showEditProfile ? (
                <AccountProfile user={currentUser} />
              ) : (
                <EditProfileForm
                  onSave={toggleEditProfileForm}
                  user={currentUser}
                />
              )}
            </Col>
            <Col md={3}>
              <CasesMenu user={currentUser} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return <div>Fetching user</div>;
  }
};

export default UserAccount;
