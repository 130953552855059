import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

import { authActions } from "../../store/auth";

import MessageAlert from "./MessageAlert";

const MainHeader = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const alertObject = useSelector((state) => state.alert);
  const history = useHistory();

  const logoutHandler = () => {
    dispatch(authActions.logout());
    history.push("/login");
  };

  const myAccountHandler = (event) => {
    event.preventDefault();
    history.push("/account");
  };

  return (
    <Fragment>
      <Navbar bg="light" expand="md" className="p-3">
        <Container>
          <Navbar.Brand href="/account">Feedback Central</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-menu" />
          <Navbar.Collapse id="navbar-menu">
            <Nav className="ms-auto">
              {isAuthenticated && (
                <Nav.Link onClick={myAccountHandler}> Account</Nav.Link>
              )}
              {!isAuthenticated && <Nav.Link href="/login"> Login </Nav.Link>}
              {isAuthenticated && (
                <Nav.Link onClick={logoutHandler}> Logout </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {alertObject.showAlert && (
        <MessageAlert
          message={alertObject.message}
          type={alertObject.alertType}
        />
      )}
    </Fragment>
  );
};

export default MainHeader;
