import Alert from "react-bootstrap/Alert";
// import Container from "react-bootstrap/Container";

import { useState } from "react";

const MessageWarningFileType = (props) => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <p className="text-center">
          Only upload files extension .doc, .docx or .pdf
        </p>
      </Alert>
    );
  }
  return <div></div>;
};

export default MessageWarningFileType;
