import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

import ReviewerCardItem from "./ReviewerCardItem";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { alertActions } from "../../store/alert";

const ReviewerCard = () => {
  const [reviewerList, setReviewerList] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const getReviewerList = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/reviewers/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          const fetchedReviewers = response.data;
          setReviewerList(fetchedReviewers);
        }
      } catch (err) {
        const errMessage = err.response.data.detail;
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    getReviewerList();
  }, [accessToken, dispatch]);

  return (
    <Container>
      <Card>
        <Card.Header>
          <h4>Reviewers</h4>
        </Card.Header>
        <Table stripped="true">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Name</th>
              <th>Active Cases</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reviewerList.map((reviewer) => (
              <ReviewerCardItem key={reviewer.id} reviewer={reviewer} />
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default ReviewerCard;
