import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import Container from "react-bootstrap/esm/Container";
import { Fragment } from "react";

const UploadFile = (props) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileType = props.type.toLowerCase().split(" ")[0];
  const caseId = props.case;
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();

  const fileInputHandler = (event) => {
    event.preventDefault();
    setFileToUpload(event.target.files[0]);
  };

  async function uploadFileHandler(event) {
    event.preventDefault();

    if (!fileToUpload) {
      return;
    }

    setIsLoading(true);
    const fileData = new FormData();
    fileData.append("file", fileToUpload);
    fileData.append("file_type", fileType);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/upload_file/${caseId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: fileData,
      });
      if (response.status === 201) {
        setIsLoading(false);
        setFileToUpload(null);
        window.location.reload();
      }
    } catch (err) {
      if (err.response.status === 404) {
        dispatch(
          alertActions.flashMessage({
            message: err.response.statusText,
            alertType: "danger",
          })
        );
        setIsLoading(false);
        setFileToUpload(null);
      }
      let errMessage = err.response.data.detail;
      dispatch(
        alertActions.flashMessage({
          message: errMessage,
          alertType: "danger",
        })
      );
      setIsLoading(false);
      setFileToUpload(null);
    }
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col className="mx-auto" md={3}>
            <Spinner className="my-2" animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Fragment>
      {props.canUpload && (
        <ListGroup.Item>
          <Row p={2}>
            <Col md={2}>{props.type}</Col>
            <Col md={4}>
              <InputGroup onChange={fileInputHandler} className="mb-3">
                <Form.Control type="file" id={fileType} />
                <Button onClick={uploadFileHandler} variant="success">
                  Upload
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </ListGroup.Item>
      )}
    </Fragment>
  );
};

export default UploadFile;
