import Container from "react-bootstrap/Container";
import Landing from "../components/home/Landing";

const Home = () => {
  return (
    <Container>
      <Landing />
    </Container>
  );
};

export default Home;
