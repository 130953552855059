import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";

import { useHistory } from "react-router-dom";

const CasesMenu = (props) => {
  const history = useHistory();

  const currentUserType = props.user.is_superuser
    ? "superuser"
    : props.user.group;

  const myCasesHandler = (event) => {
    event.preventDefault();
    history.push("/cases");
  };

  const unallocatedCasesHandler = (event) => {
    event.preventDefault();
    history.push("/unallocated_cases");
  };

  const registerUserHandler = (event) => {
    event.preventDefault();
    history.push("/register_user");
  };

  const studentListHandler = (event) => {
    event.preventDefault();
    history.push("/students");
  };

  const reviewerListHandler = (event) => {
    event.preventDefault();
    history.push("/reviewers");
  };

  const caseMenuButtons = {
    myCases: { id: 1, handler: myCasesHandler, text: "My Cases" },
    unallocatedCases: {
      id: 2,
      handler: unallocatedCasesHandler,
      text: "Unallocated Cases",
    },
    registerUser: {
      id: 3,
      handler: registerUserHandler,
      text: "Register User",
    },
    studentList: { id: 4, handler: studentListHandler, text: "Student List" },
    reviewerList: {
      id: 5,
      handler: reviewerListHandler,
      text: "Reviewer List",
    },
  };

  const userTypes = {
    superuser: [
      caseMenuButtons.registerUser,
      caseMenuButtons.studentList,
      caseMenuButtons.reviewerList,
    ],
    student: [caseMenuButtons.myCases],
    reviewer: [caseMenuButtons.myCases, caseMenuButtons.unallocatedCases],
  };

  const menuButtons = userTypes[currentUserType]
    ? userTypes[currentUserType].map((button) => (
        <Button key={button.id} onClick={button.handler}>
          {button.text}
        </Button>
      ))
    : [];

  return (
    <Card>
      <Card.Header>Options</Card.Header>
      <Card.Body>
        <div className="d-grid gap-2">{menuButtons}</div>
      </Card.Body>
    </Card>
  );
};

export default CasesMenu;
