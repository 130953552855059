import CaseActions from "../components/case/CaseActions";
import CasesCard from "../components/case/CasesCard";
import { Fragment } from "react";

const Cases = () => {
  return (
    <Fragment>
      <CaseActions />
      <CasesCard />
    </Fragment>
  );
};

export default Cases;
