import Badge from "react-bootstrap/Badge";

const StatusBadge = (props) => {
  const statusMap = {
    P: ["pending", "warning"],
    S: ["started", "success"],
    C: ["completed", "secondary"],
  };

  let status, badgeColor;

  [status, badgeColor] = statusMap[props.status];

  return <Badge bg={`badge bg-${badgeColor}`}> {status} </Badge>;
};

export default StatusBadge;
