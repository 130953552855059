import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FileDownload from "js-file-download";
import { alertActions } from "../../store/alert";
import { useHistory } from "react-router-dom";

const FileCardItem = (props) => {
  const file = props.file;
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const history = useHistory();

  const fileTypeMap = {
    CV: "Curriculum",
    CL: "Cover Letter",
    JA: "Job Ad",
    RE: "Review",
  };

  const downloadFileHandler = async (event) => {
    event.preventDefault();
    const downloadFile = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/download_file/${file.id}`,
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        });

        if (response.status === 200) {
          FileDownload(response.data, file.file_name);
        }
      } catch (err) {
        if (err.response.status === 404) {
          dispatch(
            alertActions.flashMessage({
              message: err.response.statusText,
              alertType: "danger",
            })
          );
        }
        let errMessage = err.response.data.detail;
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    await downloadFile();
  };

  async function deleteFileHandler(event) {
    event.preventDefault();
    const deleteFile = async () => {
      try {
        const response = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_BACKEND_SERVER}/api/file/${file.id}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 202) {
          const responseData = response.data.message;
          dispatch(
            alertActions.flashMessage({
              message: responseData,
              alertType: "success",
            })
          );
        }
      } catch (err) {
        if (err.response.status === 404) {
          dispatch(
            alertActions.flashMessage({
              message: err.response.statusText,
              alertType: "danger",
            })
          );
        }
        let errMessage = err.response.data.detail;
        dispatch(
          alertActions.flashMessage({
            message: errMessage,
            alertType: "danger",
          })
        );
      }
    };
    await deleteFile();
    history.push(`/cases/${props.case}`);
    window.location.reload();
  }

  return (
    <ListGroup.Item>
      <Row p={2}>
        <Col md={2}>{fileTypeMap[file.file_type]}</Col>
        <Col md={4}>{file.file_name}</Col>
        <Col md={2}>
          <Button variant="success" onClick={downloadFileHandler}>
            Download
          </Button>{" "}
        </Col>
        <Col md={2}>
          {props.canDelete && (
            <Button variant="danger" onClick={deleteFileHandler}>
              Delete
            </Button>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default FileCardItem;
