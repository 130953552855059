import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import axios from "axios";

const ForgotPasswordForm = () => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const isEmailValid = enteredEmail.trim() !== "" && enteredEmail.includes("@");

  const dispatch = useDispatch();
  const history = useHistory();

  const emailInputChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const emailBlur = (event) => {
    setEmailBlurred(true);
  };

  let isFormValid = false;

  if (isEmailValid) {
    isFormValid = true;
  }

  async function submitHandler(event) {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    try {
      setIsSendingEmail(true);
      const body = { email: enteredEmail };

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_SERVER}/api/password_reset/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      });

      if (response.status === 200) {
        dispatch(
          alertActions.flashMessage({
            message: `an email was sent to ${enteredEmail}`,
            alertType: "success",
          })
        );

        setIsSendingEmail(false);

        history.replace("/login");
      }
    } catch (err) {
      const message = err.response.data.email[0];
      dispatch(
        alertActions.flashMessage({
          message,
          alertType: "danger",
        })
      );
      setIsSendingEmail(false);
      setEnteredEmail("");
    }
  }

  return (
    <Container className="my-4">
      <Row>
        <Col md={5} className="mx-auto">
          <Card>
            <Card.Header className="bg-secondary text-white">
              Forgot Password
            </Card.Header>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={enteredEmail}
                    onChange={emailInputChangeHandler}
                    onBlur={emailBlur}
                    isValid={isEmailValid}
                    isInvalid={!isEmailValid && emailBlurred}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter your email.
                  </Form.Control.Feedback>
                </Form.Group>
                {isSendingEmail ? (
                  <Row>
                    <Col className="mx-auto" md={3}>
                      <Spinner className="my-2" animation="border" />
                    </Col>
                  </Row>
                ) : (
                  <div className="d-grid mt-3">
                    <Button type="submit">Request Password Reset</Button>
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordForm;
