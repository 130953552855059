import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialToken = localStorage.getItem("token");
const initialExpires = localStorage.getItem("expires");

const initialAuthState = {
  isAuthenticated: !!initialToken,
  accessToken: initialToken,
  refreshToken: "",
  expires: initialExpires || 0,
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
    },
    login(state, action) {
      const expires = jwt_decode(action.payload.accessToken).exp * 1000;
      state.isAuthenticated = !!action.payload.accessToken;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.expires = expires;
      localStorage.setItem("token", action.payload.accessToken);
      localStorage.setItem("expires", expires);
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
