import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MainFooter = () => {
  return (
    <footer className="bg-light text-muted mt-4">
      <Container>
        <div className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom mb-4"></div>
        <Row className="text-center">
          <Col md={12}>
            <h6 className="text-uppercase fw-bold mb-4">
              <i className="fas fa-graduation-cap me-3"></i>Feedback Central
            </h6>
            <p>Helping students improve their job application</p>
            <p className="fs-7">© 2022 Timemakers Consulting</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MainFooter;
