import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

import { useState } from "react";

const MessageAlert = (props) => {
  const [show, setShow] = useState(true);

  const closeAlert = () => {
    setShow(false);
  };

  setTimeout(closeAlert, 3000);

  if (show) {
    return (
      <Container className="mt-3 text-center">
        <Alert variant={props.type} onClose={closeAlert} dismissible>
          <p>{props.message}</p>
        </Alert>
      </Container>
    );
  }
  return <div></div>;
};

export default MessageAlert;
