import Container from "react-bootstrap/Container";

const NotFound = () => {
  return (
    <Container>
      <h1 className="my-4 text-center">404 PAGE NOT FOUND</h1>
    </Container>
  );
};

export default NotFound;
