import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import alertReducer from "./alert";
import meReducer from "./me";

const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    me: meReducer,
  },
});

export default store;
