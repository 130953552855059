import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  showAlert: false,
  message: "",
  alertType: "success",
};

const alertSlice = createSlice({
  name: "alert",
  initialState: initialAuthState,
  reducers: {
    flashMessage(state, action) {
      state.showAlert = true;
      state.message = action.payload.message;
      state.alertType = action.payload.alertType;
    },
    hideMessage(state) {
      state.showAlert = false;
    },
  },
});

export const alertActions = alertSlice.actions;

export default alertSlice.reducer;
